<template>
    <system-page :page-settings="page">
        <div class="c-content">
            <div class="shopName u-text-center">{{ shopName }}</div>
            <p>「ポケカジムエントリーキャンペーン」にてジムスタンプ獲得による景品受け取りは、マイジムでのみ行えます。受け取り希望の場合、先に上記のジムをマイジム登録ください。マイジム登録は、1年以内に参加したポケモンカードジムのみ、最大3店舗まで登録可能です。</p>
            <div class="checkin-bottom u-text-center to-mypage">
                <router-link
                    :to="{name: 'MyGym'}"
                    class="c-btn c-btn-primary-outline"
                >
                    マイジム登録はこちら
                </router-link>
                <p class="u-text-kome u-text-left">マイジム登録をした店舗は、3ヶ月間登録の解除ができません</p>
                <p class="u-text-kome u-text-left">ポケモンカードジム側の操作により、プレイヤーズクラブにイベント結果が登録された際に、そのイベントの参加履歴が反映されます。イベント結果が反映されるまでは、マイジム登録はできませんのでご注意ください</p>
            </div>
        </div>
    </system-page>
</template>

<script>
import SystemPage from '@/component/SystemPage.vue'
export default {
    name: 'PrizeErrorPage',
    components: {
        SystemPage,
    },
    props: {
        shopName: {
            type: String,
            require: true,
        },
    },
    data() {
        return {
            page: {
                title: 'マイジムではないため、景品の受け取りができません。',
                desc: 'ポケモンカードゲーム プレイヤーズクラブ イベントチェックインのページ。',
                breadcrumb: [
                    { name: 'マイページ', path: '/mypage' },
                ],
            },
        }
    },
    methods: {},
}
</script>

<style lang="scss" scoped>
@use '~@/assets/style/global' as g;

.shopName {
    font-size: 18px;
    line-height: 24px;
    margin-bottom: 32px;;
}

.checkin-bottom {
    margin-top: 32px;

    & > .u-text-kome {
        margin-top: 8px;
        font-size: 14px;
        line-height: 21px;
    }
}

.to-mypage {
    line-height: 1;
}
</style>
