import MicroModal, {
	MicroModalConfig as MicroModalConfigBase,
} from 'micromodal'

import { Faq } from './components/Faq'
import { EnjoyPage } from './pages/Enjoy'

import { Introduction } from './components/Introduction'

interface MicroModalConfig extends MicroModalConfigBase {
	onShow?: ((modal?: HTMLElement, trigger?: HTMLElement) => void) | undefined
	onClose?: ((modal?: HTMLElement, trigger?: HTMLElement) => void) | undefined
}

export class App {
	private introduction: Introduction
	private mainElm: HTMLElement | null = null
	private slideBtnElms: NodeListOf<HTMLButtonElement> | null = null
	private enjoyPage: EnjoyPage | null = null

	private fixedMenu: HTMLElement | null = null

	private faq: Faq | null = null

	constructor() {
		this.introduction = new Introduction()

		// elm
		this.mainElm = document.querySelector('.eventTop')
		this.slideBtnElms = document.querySelectorAll(
			'.eventTop_header_nav_button',
		)

		this.fixedMenu = document.querySelector('.eventTop_globalMenu')

		this.init()
	}

	private init() {
		if (
			window.location.hash === '#enjoy' ||
			window.location.hash === '#challenge'
		) {
			this.introduction.destroy()
			this.changeCurrentType(window.location.hash.replace('#', ''))
		} else {
			this.introduction.init()
			this.introduction.addListener(
				'onIntroductionClick',
				(type: string) => this.changeCurrentType(type),
			)
		}

		this.slideBtnElms?.forEach((btnElm: HTMLButtonElement) => {
			btnElm.addEventListener('click', (e: Event) => {
				const target = e.currentTarget as HTMLButtonElement
				const type = target?.getAttribute('data-type') || ''
				this.changeCurrentType(type)
			})
		})

		// modal
		MicroModal.init({
			disableScroll: true,
			onShow: this.modalOpen.bind(this),
			onClose: this.modalClose.bind(this),
		} as MicroModalConfig)

		window.addEventListener('scroll', () => {
			const threshold = window.innerWidth <= 768 ? 200 : 0

			this.fixedMenu?.setAttribute(
				'data-visible',
				`${document.body.clientHeight - window.scrollY > window.innerHeight + threshold}`,
			)
		})

    // ページ選択済みの時は最初からボタンを表示する
    const eventSelectedPage = localStorage.getItem('eventSelectedPage')
    if (eventSelectedPage) {
      this.fixedMenu?.setAttribute('data-visible', 'true')
    }

		window.addEventListener('resize', this.resize.bind(this))

		this.faq = new Faq()
	}

	private changeCurrentType(type: string) {
		this.mainElm?.setAttribute('data-current-type', type)
		window.location.hash = type

		// enjoyページ専用スクリプト
		if (type === 'enjoy') {
			this.enjoyPage = new EnjoyPage()
		}
	}

	private modalOpen(modal?: HTMLElement, trigger?: HTMLElement, e?: Event) {
		const target = e?.currentTarget as HTMLElement

		modal?.querySelector('.eventTop_modal_dialog_inner')?.scrollTo(0, 0)

		if (modal?.getAttribute('id') === 'modal_youtube') {
			const iframe = document.createElement('iframe')
			iframe.className = 'eventTop_modal_youtube_iframe'
			iframe.allow =
				'accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share'
			iframe.referrerPolicy = 'strict-origin-when-cross-origin'
			iframe.src = `https://www.youtube-nocookie.com/embed/${target.getAttribute('data-youtube-id')}?autoplay=1`
			modal
				.querySelector('.eventTop_modal_youtube_inner')
				?.appendChild(iframe)
		}
	}

	private modalClose(modal?: HTMLElement) {
		if (modal?.getAttribute('id') === 'modal_youtube') {
			modal.querySelector('.eventTop_modal_youtube_inner')!.innerHTML = ''
		}
	}

	private resize() {
		this.faq?.onResize()
	}
}

// ;(() => {
// 	document.addEventListener('DOMContentLoaded', () => {
// 		new App()
// 	})
// })()
