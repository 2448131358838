<template>
    <system-page :page-settings="page">
        <div class="c-content">
            <p>※ジムスタンプが一定数集まった場合のみ、マイジムにて景品の受け取りが可能です。</p>
            <div class="gymStamp-shop">
                <div class="title">受け取り確認店舗</div>
                <div class="name">{{ loginShopName }}</div>
            </div>

            <div class="gymStamp-selectBox">
                <button
                    @click="setDisplayContent('thisSeason')"
                    class="c-btn button"
                    :disabled="thisSeasonParams.length === 0"
                >開催中の<br>キャンペーンの<br><span>ジムスタンプ</span></button>
                <button
                    @click="setDisplayContent('previousSeason')"
                    class="c-btn button"
                    :disabled="previousSeasonParams.length === 0"
                >過去に開催された<br>キャンペーンの<br><span>ジムスタンプ</span></button>
            </div>

            <div
                v-if="thisSeasonParams.length !== 0"
                class="p-gymStampContainer"
                id="thisSeason"
            >
                <div class="p-gymStampContainer_title">
                    開催中のキャンペーンの<br class="u-display-sp">ジムスタンプ
                </div>
                <div class="p-gymStampContainer_header">
                    <div class="detail">
                        <div class="title">{{ thisSeasonParams.season_name }}</div>
                        <div class="period">開催期間：{{ dayjsFormat(thisSeasonParams.season_start_datetime.date, 'YYYY/MM/DD') }}~{{ dayjsFormat(thisSeasonParams.season_end_datetime.date, 'YYYY/MM/DD') }}</div>
                    </div>
                    <div
                        :class="{
                                first: thisSeasonParams.stamp_color == 1,
                                second: thisSeasonParams.stamp_color == 2
                        }"
                        class="stamp-container"
                    >
                        <div class="title">あつめたジムスタンプ</div>
                        <ul class="stamp">
                            <li
                                v-for="i in thisSeasonParams.earned_count"
                                :key="`si${i}`"
                                class="get"
                            ><span class="stamp-icon"></span></li>
                            <li
                                v-for="i in (4 - thisSeasonParams.earned_count)"
                                :key="`si${i}`"
                            ><span class="stamp-icon"></span></li>
                        </ul>
                    </div>
                </div>
                <div class="p-gymStampContainer_main">
                    <div class="content">
                        <div class="explanation">
                            ジムスタンプ{{ thisSeasonParams.prize[0].cond_of_ex }}個達成で<br>
                            もらえる景品
                        </div>
                        <div class="image">
                            <img
                                v-if="thisSeasonParams.prize[0].image_url"
                                :src="thisSeasonParams.prize[0].image_url"
                                class="prize-image"
                            >
                            <img v-else src="@/assets/img/prize/no_image.png" class="prize-image">
                        </div>
                        <div class="title">
                            {{ thisSeasonParams.prize[0].name }}
                        </div>
                        <div class="desc">
                            {{ thisSeasonParams.prize[0].detail }}
                        </div>
                        <div class="period">
                            景品受け取り期限：{{ dayjsFormat(thisSeasonParams.exchange_limit_datetime.date, 'YYYY/MM/DD') }}まで
                        </div>
                        <div class="exchange">
                            <button
                                @click="openModal('prizeConfirmModal', thisSeasonParams.prize[0])"
                                class="c-btn c-btn-primary"
                                :disabled="thisSeasonParams.prize[0].possible_exchange_flg === 0 || thisSeasonParams.prize[0].exchanged_flg === 1"
                            >
                                <div v-if="thisSeasonParams.prize[0].exchanged_flg === 1">
                                    景品受け取り済み
                                </div>
                                <div v-else-if="thisSeasonParams.prize[0].possible_exchange_flg === 1">
                                    景品を受け取る
                                </div>
                                <div v-else>
                                    ジムスタンプ{{ thisSeasonParams.prize[0].cond_of_ex }}個達成で交換可能
                                </div>
                            </button>
                            <ul>
                                <li class="u-text-kome">受け取り確認店舗にて景品が欠品している場合がございます。ボタンを押す前に、必ずジム店に景品の有無をご確認ください。景品が欠品している場合は交換できません。</li>
                                <li class="u-text-kome">景品受け取り期限を超えた場合、景品は受け取れません。</li>
                                <li class="u-text-kome">ジムスタンプ{{ thisSeasonParams.prize[0].cond_of_ex }}個・{{ thisSeasonParams.prize[1].cond_of_ex }}個達成でもらえる景品を同時に受け取りたい場合は、それぞれの受け取りボタンを押して、順番に受け取りをお願いします。</li>
                            </ul>
                        </div>
                    </div>
                    <div class="content">
                        <div class="explanation">
                            ジムスタンプ{{ thisSeasonParams.prize[1].cond_of_ex }}個達成で<br>
                            もらえる景品
                        </div>
                        <div class="image">
                            <img
                                v-if="thisSeasonParams.prize[1].image_url"
                                :src="thisSeasonParams.prize[1].image_url"
                                class="prize-image"
                            >
                            <img v-else src="@/assets/img/prize/no_image.png" class="prize-image">
                        </div>
                        <div class="title">
                            {{ thisSeasonParams.prize[1].name }}
                        </div>
                        <div class="desc">
                            {{ thisSeasonParams.prize[1].detail }}
                        </div>
                        <div class="period">
                            景品受け取り期限：{{ dayjsFormat(thisSeasonParams.exchange_limit_datetime.date, 'YYYY/MM/DD') }}まで
                        </div>
                        <div class="exchange">
                            <button
                                @click="openModal('prizeConfirmModal', thisSeasonParams.prize[1])"
                                class="c-btn c-btn-primary"
                                :disabled="thisSeasonParams.prize[1].possible_exchange_flg === 0 || thisSeasonParams.prize[1].exchanged_flg === 1"
                            >
                                <div v-if="thisSeasonParams.prize[1].exchanged_flg === 1">
                                    景品受け取り済み
                                </div>
                                <div v-else-if="thisSeasonParams.prize[1].possible_exchange_flg === 1">
                                    景品を受け取る
                                </div>
                                <div v-else>
                                    ジムスタンプ{{ thisSeasonParams.prize[1].cond_of_ex }}個達成で交換可能
                                </div>
                            </button>
                            <ul>
                                <li class="u-text-kome">受け取り確認店舗にて景品が欠品している場合がございます。ボタンを押す前に、必ずジム店に景品の有無をご確認ください。景品が欠品している場合は交換できません。</li>
                                <li class="u-text-kome">景品受け取り期限を超えた場合、景品は受け取れません。</li>
                                <li class="u-text-kome">ジムスタンプ{{ thisSeasonParams.prize[0].cond_of_ex }}個・{{ thisSeasonParams.prize[1].cond_of_ex }}個達成でもらえる景品を同時に受け取りたい場合は、それぞれの受け取りボタンを押して、順番に受け取りをお願いします。</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>

            <div
                v-if="previousSeasonParams.length !== 0"
                class="p-gymStampContainer"
                id="previousSeason"
            >
                <div class="p-gymStampContainer_title">
                    過去に開催されたキャンペーンの<br class="u-display-sp">ジムスタンプ
                    <div class="u-text-kome">
                        景品受け取り期限を超えたものは表示されません。
                    </div>
                </div>
                <div class="p-gymStampContainer_header">
                    <div class="detail">
                        <div class="title">{{ previousSeasonParams.season_name }}</div>
                        <div class="period">開催期間：{{ dayjsFormat(previousSeasonParams.season_start_datetime.date, 'YYYY/MM/DD') }}~{{ dayjsFormat(previousSeasonParams.season_end_datetime.date, 'YYYY/MM/DD') }}</div>
                    </div>
                    <div
                        :class="{
                            first: previousSeasonParams.stamp_color == 1,
                            second: previousSeasonParams.stamp_color == 2
                        }"
                        class="stamp-container"
                    >
                        <div class="title">あつめたジムスタンプ</div>
                        <ul class="stamp">
                            <li
                                v-for="i in previousSeasonParams.earned_count"
                                :key="`si${i}`"
                                class="get"
                            ><span class="stamp-icon"></span></li>
                            <li
                                v-for="i in (4 - previousSeasonParams.earned_count)"
                                :key="`si${i}`"
                            ><span class="stamp-icon"></span></li>
                        </ul>
                    </div>
                </div>
                <div class="p-gymStampContainer_main">
                    <div class="content">
                        <div class="explanation">
                            ジムスタンプ{{ previousSeasonParams.prize[0].cond_of_ex }}個達成で<br>
                            もらえる景品
                        </div>
                        <div class="image">
                            <img
                                v-if="previousSeasonParams.prize[0].image_url"
                                :src="previousSeasonParams.prize[0].image_url"
                                class="prize-image"
                            >
                            <img v-else src="@/assets/img/prize/no_image.png" class="prize-image">
                        </div>
                        <div class="title">
                            {{ previousSeasonParams.prize[0].name }}
                        </div>
                        <div class="desc">
                            {{ previousSeasonParams.prize[0].detail }}
                        </div>
                        <div class="period">
                            景品受け取り期限：{{ dayjsFormat(previousSeasonParams.exchange_limit_datetime.date, 'YYYY/MM/DD') }}まで
                        </div>
                        <div class="exchange">
                            <button
                                @click="openModal('prizeConfirmModal', previousSeasonParams.prize[0])"
                                class="c-btn c-btn-primary"
                                :disabled="previousSeasonParams.prize[0].possible_exchange_flg === 0 || previousSeasonParams.prize[0].exchanged_flg === 1"
                            >
                                <div v-if="previousSeasonParams.prize[0].exchanged_flg === 1">
                                    景品受け取り済み
                                </div>
                                <div v-else-if="previousSeasonParams.prize[0].possible_exchange_flg === 1">
                                    景品を受け取る
                                </div>
                                <div v-else>
                                    ジムスタンプ{{ previousSeasonParams.prize[0].cond_of_ex }}個達成で交換可能
                                </div>
                            </button>
                            <ul>
                                <li class="u-text-kome">受け取り確認店舗にて景品が欠品している場合がございます。ボタンを押す前に、必ずジム店に景品の有無をご確認ください。景品が欠品している場合は交換できません。</li>
                                <li class="u-text-kome">景品受け取り期限を超えた場合、景品は受け取れません。</li>
                                <li class="u-text-kome">ジムスタンプ{{ previousSeasonParams.prize[0].cond_of_ex }}個・{{ previousSeasonParams.prize[1].cond_of_ex }}個達成でもらえる景品を同時に受け取りたい場合は、それぞれの受け取りボタンを押して、順番に受け取りをお願いします。</li>
                            </ul>
                        </div>
                    </div>
                    <div class="content">
                        <div class="explanation">
                            ジムスタンプ{{ previousSeasonParams.prize[1].cond_of_ex }}個達成で<br>
                            もらえる景品
                        </div>
                        <div class="image">
                            <img
                                v-if="previousSeasonParams.prize[1].image_url"
                                :src="previousSeasonParams.prize[1].image_url"
                                class="prize-image">
                            <img v-else src="@/assets/img/prize/no_image.png" class="prize-image">
                        </div>
                        <div class="title">
                            {{ previousSeasonParams.prize[1].name }}
                        </div>
                        <div class="desc">
                            {{ previousSeasonParams.prize[1].detail }}
                        </div>
                        <div class="period">
                            景品受け取り期限：{{ dayjsFormat(previousSeasonParams.exchange_limit_datetime.date, 'YYYY/MM/DD') }}まで
                        </div>
                        <div class="exchange">
                            <button
                                @click="openModal('prizeConfirmModal', previousSeasonParams.prize[1])"
                                class="c-btn c-btn-primary"
                                :disabled="previousSeasonParams.prize[1].possible_exchange_flg === 0 || previousSeasonParams.prize[1].exchanged_flg === 1"
                            >
                                <div v-if="previousSeasonParams.prize[1].exchanged_flg === 1">
                                    景品受け取り済み
                                </div>
                                <div v-else-if="previousSeasonParams.prize[1].possible_exchange_flg === 1">
                                    景品を受け取る
                                </div>
                                <div v-else>
                                    ジムスタンプ{{ previousSeasonParams.prize[1].cond_of_ex }}個達成で交換可能
                                </div>
                            </button>
                            <ul>
                                <li class="u-text-kome">受け取り確認店舗にて景品が欠品している場合がございます。ボタンを押す前に、必ずジム店に景品の有無をご確認ください。景品が欠品している場合は交換できません。</li>
                                <li class="u-text-kome">景品受け取り期限を超えた場合、景品は受け取れません。</li>
                                <li class="u-text-kome">ジムスタンプ{{ previousSeasonParams.prize[0].cond_of_ex }}個・{{ previousSeasonParams.prize[1].cond_of_ex }}達成でもらえる景品を同時に受け取りたい場合は、それぞれの受け取りボタンを押して、順番に受け取りをお願いします。</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="u-text-center to-mypage">
            <router-link
                :to="{name: 'Mypage'}"
                class="c-btn c-btn-primary-outline"
            >
                マイページへ
            </router-link>
        </div>
    </system-page>

    <pcg-modal
        class="prizeConfirmModal"
        :isActive="activeModal === 'prizeConfirmModal'"
        :bgImage="true"
        @close="closeModal()"
    >
        <template #modalHeader>
            <h2 class="c-title c-title-page">景品を受け取りますか？</h2>
        </template>
        <div class="confirm">
            <dl>
                <dt>受取人：</dt>
                <dd><span>プレイヤーID：</span>{{ user.player_id }}</dd>
                <dd><span>ニックネーム：</span>{{ user.nickname }}</dd>
                <dt class="margin">配布ジム：</dt>
                <dd>{{ loginShopName }}</dd>
            </dl>
            <div class="image">
                <img
                    v-if="exchangePrize.image_url"
                    :src="exchangePrize.image_url"
                    class="prize-image">
                <img v-else src="@/assets/img/prize/no_image.png" class="prize-image">
            </div>
            <div class="prizeName">{{ exchangePrize.name }}</div>
        </div>
        <div class="attention">
            <p class="text-bolder">
                <span>こちらの画面は、必ず[配布ジム]の担当者の指示に従って操作をしてください。</span>
                まずは対象の景品について欠品状況を確認してください。欠品の場合は「戻る」を押し、受け取りを中止してください。景品が欠品していない場合、受け取る景品の画像と、用意された実際の景品の内容が一致していることをジムイベント担当者とともに確認してください。
            </p>
            <p class="u-text-kome">担当者による確認が済む前に受け取り済み画面まで進んでしまった場合、景品は受け取れません。操作ミスにご注意ください。</p>
        </div>
        <div class="check">
            <div class="c-checkbox">
                <input
                    v-model="exchangeConfirm"
                    id="exchangeConfirmCheckbox"
                    type="checkbox"
                    name="prefectureAreaCheck"
                >
                <label for="exchangeConfirmCheckbox">
                    担当者とともに、上記景品の受け取りが可能であることを確認しました。
                </label>
            </div>
            <div class="button">
                <button
                    @click="excuteExchange(exchangePrize)"
                    class="c-btn c-btn-primary"
                    v-bind:disabled="isExchangeConfirmDisabled"
                >景品を受け取る</button>
                <button
                    class="c-btn close"
                    @click="closeModal()"
                >戻る</button>
            </div>
        </div>
    </pcg-modal>
    <pcg-modal
        class="prizeCompleteModal"
        :isActive="activeModal === 'prizeCompleteModal'"
        :bgImage="true"
        @close="closeModal()"
    >
        <template #modalHeader>
            <h2 class="c-title c-title-page">景品受け取り済み</h2>
        </template>
        <div class="confirm">
            <dl>
                <dt>受取人：</dt>
                <dd><span>プレイヤーID：</span>{{ user.player_id }}</dd>
                <dd><span>ニックネーム：</span>{{ user.nickname }}</dd>
                <dt>配布ジム：</dt>
                <dd>{{ loginShopName }}</dd>
            </dl>
            <div class="image">
                <img
                    v-if="exchangePrize.image_url"
                    :src="exchangePrize.image_url"
                    class="prize-image"
                >
                <img v-else src="@/assets/img/prize/no_image.png" class="prize-image">
            </div>
            <div class="prizeName">{{ exchangePrize.name }}</div>
        </div>
        <div class="attention">
            <p class="u-text-kome">担当者による確認が済む前に受け取り済み画面まで進んでしまった場合、景品は受け取れません。</p>
        </div>
        <div class="check">
            <div class="button">
                <button
                    class="c-btn close"
                    @click="closeModal()"
                >他の景品も受け取る/確認する</button>
            </div>
        </div>
    </pcg-modal>
    <pcg-modal
        class="ConfirmErrorModal"
        :isActive="activeModal === 'ConfirmErrorModal'"
        :bgImage="true"
        @close="closeModal()"
    >
        <template #modalHeader>
            <h2 class="c-title c-title-page">{{ errorMessage }}</h2>
        </template>
        <div class="u-text-center">
            <div class="button">
                <button
                    class="c-btn close"
                    @click="closeModal()"
                >戻る</button>
            </div>
        </div>
    </pcg-modal>
</template>

<script>
import SystemPage from '@/component/SystemPage.vue'
import pcgModal from '@/component/Modal.vue'
import dayjs from 'dayjs'
import ja from 'dayjs/locale/ja'

export default {
    name: 'PrizeConfirmPage',
    computed: {
        isLogin() {
            return this.$store.state.loggedIn
        },
        user() {
            return this.$store.getters.getUser()
        },
        isExchangeConfirmDisabled() {
            return !this.exchangeConfirm
        },
    },
    watch: {
        '$route': function() {
            this.user = this.$store.getters.getUser()
        },
    },
    components: {
        SystemPage,
        pcgModal,
    },
    props: {
        shopName: {
            type: String,
            require: true,
        },
        shopId: {
            type: String,
            require: true,
        },
        thisSeason: {
            type: String,
            require: true,
            default: '',
        },
        previousSeason: {
            type: String,
            require: true,
            default: '',
        },
    },
    data() {
        return {
            page: {
                title: 'ジムスタンプ獲得による景品受け取りの確認',
                desc: 'ポケモンカードゲーム プレイヤーズクラブ ジムスタンプ獲得による景品受け取りの確認のページ。',
                breadcrumb: [
                    { name: 'マイページ', path: '/mypage' },
                ],
            },
            thisSeasonParams: this.thisSeason ? JSON.parse(this.thisSeason) : [],
            previousSeasonParams: this.previousSeason ? JSON.parse(this.previousSeason) : [],
            exchangeConfirm: false,
            activeModal: null,
            displayContent: 'first'
        }
    },
    created: function() {
        this.readLoginShopData();
        if (!this.loginShopName || !this.loginShopId)
        {
            this.$router.push({name: 'NotFound'})
            return
        }

        window.addEventListener("beforeunload", this.beforeReload)

        if (this.thisSeasonParams.length !== 0)
        {
            this.setDisplayContent('first');
        }
        else if (this.previousSeasonParams.length !== 0)
        {
            this.setDisplayContent('second');
        }
    },
    methods: {
        openModal(name, prize, errorMessage) {
            this.activeModal = name
            this.exchangePrize = prize
            this.errorMessage = errorMessage
        },
        closeModal() {
            this.activeModal = null
            this.exchangeConfirm = false
        },
        setDisplayContent(period) {
            const element = document.getElementById(period)
            element.scrollIntoView({behavior: 'smooth', block: 'start'})
        },
        dayjsFormat(date, format) {
            dayjs.locale(ja)

            return dayjs(date).format(format)
        },
        beforeReload() {
            sessionStorage.setItem('loginShopId', this.loginShopId);
            sessionStorage.setItem('loginShopName', this.loginShopName);
        },
        excuteExchange(exchangePrize) {
            let params = new URLSearchParams;
            params.append('shop_id', this.loginShopId)
            params.append('prize_id', exchangePrize.id)

            const api = '/exchange_prize'

            this.axios.post(api, params)
            .then(response => {
                if (0 === Number(response.data.status)) {
                    this.error = response.data.message;
                    this.processing = false;

                    return
                }

                this.fetchStampPrize()
                this.openModal('prizeCompleteModal', exchangePrize)
            })
            .catch(err => {
                this.fetchStampPrize()
                this.openModal('ConfirmErrorModal', [], err.response.data.message)
            })
        },
        readLoginShopData() {
            if (this.shopId && this.shopName)
            {
                this.loginShopId = this.shopId
                this.loginShopName = this.shopName
                return
            }

            const perfEntries = performance.getEntriesByType("navigation")
            // 画面を更新した場合に限り、SessionStorageに一時保存した店舗情報を復元して景品情報を取得し直す
            if (perfEntries[0].type === 'reload' && sessionStorage.getItem('loginShopId') && sessionStorage.getItem('loginShopName'))
            {
                this.loginShopId = sessionStorage.getItem('loginShopId');
                this.loginShopName = sessionStorage.getItem('loginShopName');
                this.fetchStampPrize();

                sessionStorage.removeItem('loginShopId');
                sessionStorage.removeItem('loginShopName');
            }
        },
        fetchStampPrize() {
            const api = '/get_stamp_prize'

            this.axios.get(api)
            .then(response => {
                if (0 === Number(response.data.status)) {
                    this.error = response.data.message;
                    this.processing = false;

                    return
                }

                const nowDate = this.dayjsFormat(new Date(), 'YYYY/MM/DD');

                if (response.data.this_season?.exchange_limit_datetime) {
                    const limitDate = this.dayjsFormat(response.data.this_season.exchange_limit_datetime.date, 'YYYY/MM/DD');
                    this.thisSeasonParams = (limitDate >= nowDate) ? response.data.this_season : [];
                } else {
                    this.thisSeasonParams = []
                }

                if (response.data.previous_season?.exchange_limit_datetime) {
                    const limitDate = this.dayjsFormat(response.data.previous_season.exchange_limit_datetime.date, 'YYYY/MM/DD');
                    this.previousSeasonParams = (limitDate >= nowDate) ? response.data.previous_season : [];
                } else {
                    this.previousSeasonParams = []
                }
            })
            .catch(err => {
                this.error = err.response.data.message
            })
        },
    },
}
</script>

<style lang="scss" scoped>
@use '~@/assets/style/global' as g;
@use './modal';

.u-text-kome {
    font-size: 14px;
    line-height: 21px;
    color: #3C3C3C;
}

.gymStamp-shop {
    & {
        margin-top: 32px;;
        text-align: center;
    }
    & > .title {
        & {
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 12px;
            line-height: 1.4;
        }
        &::before {
            @include g.setIconFont('\e939');
            margin-right: 8px;
            font-weight: normal;
            font-size: 29px;
            color: g.$color-black-a040;
        }
    }
    & > .name {
        & {
            font-size: 18px;
            line-height: 24px;
            font-weight: bolder;
        }
    }
}

.gymStamp-selectBox {
    & {
        position: relative;
        margin: 32px auto;
        padding: 8px;
        width: fit-content;
        background-color: g.$color-black-a003;
    }
    &::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        margin: auto;
        width: 1px;
        height: calc(100% - 16px);
        background-color: g.$color-border-gray;
    }
    & > .button {
        & {
            position: relative;
            width: 148px;
            height: auto;
            padding-bottom: 10px;
            font-size: 12px;
            line-height: 1.4;
            text-align: center;
            font-weight: bolder;
            color: g.$color-black-a080;
        }
        & span {
            font-size: 16px;
            line-height: 1.3125;
        }
        &::after {
            @include g.setIconFont('\e900');
            font-size: 6px;
            position: absolute;
            left: 0;
            right: 0;
            bottom: 0;
            margin: auto;
        }
        &:disabled {
            border: 1px solid g.$color-border-gray;
            border-radius: 0;
            color: g.$color-black-a040;
        }
    }
}

.p-gymStampContainer {
    margin-bottom: 10px;
}

.to-mypage {
    margin-top: 66px;
    line-height: 1;
}
</style>
