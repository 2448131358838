<template>
    <system-page :page-settings="page">
        <error-msg class="u-mb-lg error-msg" v-if="error" :message="error">
        </error-msg>
        <div class="c-box login">
            <div class="form">
                <form>
                    <div class="u-mb">
                        <label for="formMailAddress" class="u-mb-sm u-display-block">メールアドレスまたはプレイヤーID</label>
                        <input
                            v-model="playerId"
                            id="formMailAddress"
                            type="text"
                            class="c-input c-input-text u-width-100"
                            :class="{'c-input-error': hasPlayerIdError}"
                        >
                    </div>
                    <div class="u-mb">
                        <label for="formMailAddress" class="u-mb-sm u-display-block">パスワード</label>
                        <password-input
                            v-model:password="password"
                            :hasError="hasPasswordError"
                            id="formPassword"
                        ></password-input>
                        <p class="u-text-center"><router-link :to="{ name: 'PasswordReissue' }">パスワードを忘れた方</router-link></p>
                    </div>
                    <div class="u-text-center">
                        <button
                            @click.prevent="login"
                            type="submit"
                            :disabled="processing"
                            class="c-btn c-btn-primary"
                        >
                            ログイン
                        </button>
                    </div>
                </form>
            </div>
        </div>
    </system-page>
</template>

<script>
import SystemPage from '@/component/SystemPage.vue'
import errorMsg from '@/component/ErrorMsg.vue'
import passwordInput from '@/component/PasswordInput.vue'

export default {
    name: 'QrLoginForPrize',
    components: {
        SystemPage,
        errorMsg,
        passwordInput,
    },
    props: {},
    data: function() {
        return {
            page: {
                title: '景品交換ログイン',
                desc: 'ポケモンカードゲーム プレイヤーズクラブ デジタルスタンプ景品交換用のログインページ。',
            },
            error: null,
            playerId: '',
            password: '',
            hasPlayerIdError: false,
            hasPasswordError: false,
            processing: false,
        }
    },
    created: function() {
        if (this.$route.query.code && this.$route.query.code != 200)
        {
            this.error = this.$route.query.message;
        }
        this.init()
    },
    methods: {
        init() {},
        login() {
            if (!this.playerId)
            {
                this.error = 'メールアドレスを入力してください。'
                this.hasPlayerIdError = true
                return
            }
            if (!this.password)
            {
                this.error = 'パスワードを入力してください。'
                this.hasPasswordError = true
                return
            }
            this.processing = true;

            let params = new URLSearchParams;
            params.append('player_id', this.playerId)
            params.append('password', this.password)
            params.append('shop_id', this.$route.query.shop_id)
            params.append('shop_hash', this.$route.query.shop_hash)

            const api = '/get_possible_stamp_prize'

            this.axios.post(api, params)
            .then(response => {
                if (0 === Number(response.data.status)) {
                    this.error = response.data.message;
                    this.processing = false;

                    return
                }

                this.$store.commit('setLoginState', {
                    userToken: response.data.token,
                    userTokenDeadline: response.data.token_deadline,
                    userName: response.data.user_name,
                    mail: response.data.email,
                    phoneNumber: response.data.phone_number,
                })

                this.$router.push({name: 'PrizeCheckinConfirm', params: {
                    shopName: response.data.shop_name,
                    shopId: this.$route.query.shop_id,
                    thisSeason: JSON.stringify(response.data.this_season),
                    previousSeason: JSON.stringify(response.data.previous_season),
                }})
            })
            .catch(err => {
                this.processing = false;
                if (err.response.data.code == '400') {
                    this.$router.push({name: 'PrizeExchangeError', params: {shopName: err.response.data.shop_name}})

                    return
                }

                if (err.response.data.code == '403') {
                    this.$router.push({name: 'PrizeEventError', params: {message: err.response.data.message}})

                    return
                }

                this.error = err.response.data.message
            })
        }
    },
}
</script>

<style lang="scss" scoped>
@use '~@/assets/style/global' as g;
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@500&display=swap');

.login {
    padding: 50px;
    width: 640px;
    margin: 0 auto;
    font-size: 14px;

    .form {
        padding: 0 50px g.$margin-large;
    }

    .password-outer {
        margin-bottom: 24px;

        .password {
            margin-bottom: 17px;
        }
    }
}

@include g.sp {
    .login {
        width: 100%;
        padding: 0 20px;

        .form {
            padding: 0;
        }
    }
}

.error-msg {
    @include g.pc {
        width: 480px;
    }
    @include g.sp {
        width: 335px;
    }
}
</style>
