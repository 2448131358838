<template>
    <system-page :page-settings="page">
        <div class="c-content u-text-center">
            <p>チェックインが完了しました。</p>
            <p>チェックイン後は、会場のアナウンスに<br class="u-display-sp">従ってください。</p>
            <p>ルール・マナーを守って、<br class="u-display-sp">最強のトレーナーを目指そう！</p>
            <template v-if="stampGrant">
                <div
                    v-if="isStampImageDisplay"
                    :class="[{
                        first: stampColor == 1,
                        second: stampColor == 2
                    }, {
                        get: stampGrant == 2
                    }]"
                    class="gymStamp-content"
                >
                    <div class="image">
                        ジムスタンプ
                    </div>
                    <div class="text">ジムスタンプGET!</div>
                </div>
                <div class="gymStamp-desc">
                    <div class="title">{{ seasonName }}</div>
                    <div class="getstamp">
                        <div class="title">あつめたジムスタンプ</div>
                        <div class="stamp">
                            合計<span class="num">{{ displayStampCount }}</span>個<span class="slash">/</span><span class="small">{{ maxStampCount }}個</span>
                        </div>
                    </div>
                    <div class="link">
                        <router-link :to="{name: 'MypageStamp'}">ジムスタンプ獲得でもらえる景品・受け取り方法・期限について</router-link>
                    </div>
                </div>
            </template>
            <div class="checkin-bottom u-text-center to-mypage">
                <router-link
                    :to="{name: 'Mypage'}"
                    class="c-btn c-btn-primary-outline"
                >
                    マイページへ
                </router-link>
            </div>
        </div>
    </system-page>
</template>

<script>
import SystemPage from '@/component/SystemPage.vue'
const MAX_STAMP_COUNT = 4

export default {
    name: 'StampQrCheckinPage',
    components: {
        SystemPage,
    },
    props: {
        stampGrant: {
            type: String,
            default: null
        },
        seasonName: {
            type: String,
            default: null
        },
        stampColor: {
            type: String,
            default: null
        },
        earnedStampCount: {
            type: String,
            default: null
        },
    },
    data() {
        return {
            page: {
                title: 'イベントチェックイン',
                desc: 'ポケモンカードゲーム プレイヤーズクラブ イベントチェックインのページ。',
                breadcrumb: [],
            },
            maxStampCount: MAX_STAMP_COUNT
        }
    },
    computed: {
        isStampImageDisplay() {
            if (this.stampGrant == 1 || (this.stampGrant == 2 && this.earnedStampCount < MAX_STAMP_COUNT)) {
                return true
            } else {
                return false
            }
        },
        displayStampCount() {
            return this.earnedStampCount > MAX_STAMP_COUNT ? MAX_STAMP_COUNT : this.earnedStampCount
        },
    },
    mounted: function() {
    },
    methods: {},
}
</script>

<style lang="scss" scoped>
@use '~@/assets/style/global' as g;

.c-content {
    & > p {
        font-size: 15px;
    }
}

.gymStamp-content {
    &.first {
        & > .image {
            & {
                background-image: url('@/assets/img/prize/stamp-first.png');
            }
            &::after {
                content: url('@/assets/img/prize/badge-first.png');
            }
        }
        & > .text {
            color: #FD2828;
        }
    }
    &.second {
        & > .image {
            & {
                background-image: url('@/assets/img/prize/stamp-second.png');
            }
            &::after {
                content: url('@/assets/img/prize/badge-second.png');
            }
        }
        & > .text {
            color: #2886FD;
        }
    }
    &.get {
        & {
            position: relative;
            overflow: hidden;
        }
        &::after {
            content: '';
            display: block;
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            background-image: url('@/assets/img/prize/g_stamp.png');
            background-repeat: no-repeat;
            background-position: center;
            background-color: rgba(g.$color-black, .5);
        }
    }
    & {
        max-width: 303px;
        margin: auto;
        margin-top: 32px;
        padding: 34px 0;
        text-align: center;
        border: 2px solid #F6F6F6;
        border-radius: 8px;
    }
    & > .image {
        & {
            position: relative;
            width: 134px;
            height: 134px;
            margin: auto;
            font-size: 0;
            background-repeat: no-repeat;
            background-position: center;
            background-size: contain;
        }
        &::after {
            position: absolute;
            right: -9px;
            top: -9px;
        }
    }
    & > .text {
        margin-top: 8px;
        font-size: 21px;
        line-height: 1.3;
        font-weight: 900;
    }
}

.gymStamp-desc {
    & > .title {
        margin-top: 32px;
        font-size: 13px;
        line-height: 27px;
        font-weight: bolder;
        text-align: center;
    }
    & > .getstamp {
        & {
            display: flex;
            width: fit-content;
            min-width: 303px;
            height: 47px;
            margin: auto;
            margin-top: 8px;
            border: 1px solid #BAE3F0;
            border-radius: 6px;
            overflow: hidden;
        }
        & > .title {
            & {
                display: flex;
                align-items: center;
                justify-content: center;
                padding: 0 12px;
                font-size: 12px;
                line-height: 18px;
                font-weight: bolder;
                color: #31576F;
                background-color: #BAE3F0;
            }
        }
        & > .stamp {
            & {
                display: inline-block;
                vertical-align: middle;
                height: 29px;
                margin: auto 8px;
                font-size: 14px;
                line-height: 18px;
                font-weight: bolder;
                background: g.$color-white;
            }
            & > .num {
                margin: 0 2px 0 1px;
                font-size: 20px;
                line-height: 1.448;
            }
            & > .slash {
                margin-left: 2px;
            }
            & > .small {
                font-size: 9px;
            }
        }
    }
    & > .link {
        & {
            margin-top: 8px;
            line-height: 24px;
        }
        & > a {
            display: inline-flex;
            align-items: center;
            gap: 2px;
            font-size: 9px;

            &::before {
                @include g.setIconFont('\e90e');
                font-size: 12px;
            }
        }
    }
}

.to-mypage {
    margin-top: 32px;
    line-height: 1;
}
</style>
