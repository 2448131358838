<template>
    <system-page :page-settings="page">
        <div class="c-content">
            <h3 class="u-display-pc">ジムスタンプについて</h3>
            <button
                @click="descContent.stamp = !descContent.stamp"
                :class="{
                    'open': (descContent.stamp && isSp),
                    'close': (!descContent.stamp && isSp),
                }"
                class="h3-button stamp"
            ><span>ジムスタンプについて</span></button>

            <div
                :class="{
                    'open': (descContent.stamp && isSp),
                    'close': (!descContent.stamp && isSp),
                }"
                class="stamp-toggle-content"
            >
                <img
                    class="u-mt-20 stamp-banner"
                    src="@/assets/img/prize/stamp_banner.png"
                >
                <p class="u-mt-20">「ポケカジムエントリーキャンペーン」では、ポケモンカードジムのジムイベントに参加するごとに、ジムスタンプ1個を獲得できます。指定の期間中にジムスタンプを2個獲得・4個獲得すると、それぞれ景品を受け取ることができます。景品は、指定の期限までに1個ずつ受け取れます。ジムスタンプの獲得には、プレイヤーズクラブへの登録が必要です。</p>
                <ul class="u-mt-20">
                    <li class="u-text-kome">イベント参加時に二次元コードでのチェックインが行われていなかった場合、ジムスタンプは獲得できません。</li>
                    <li class="u-text-kome">ジムスタンプは期間中、最大4個まで集めることができます。ジムイべントに5回以上参加しても、ジムスタンプはそれ以上獲得できず、追加で受け取れる景品もございません。</li>
                    <li class="u-text-kome">ジムエントリーキャンペーンは半年ごとに景品・開催期間が切り替わる予定です。開催期間が終了する度に、あつめたスタンプの数はリセットされ、次の期間中に再びスタンプをあつめることができ、あつまったスタンプに応じて景品を受け取ることができます。</li>
                </ul>
            </div>

            <h3 class="u-display-pc u-mt-48">景品の受け取り方法</h3>
            <button
                @click="descContent.prize = !descContent.prize"
                :class="{
                    'open': (descContent.prize && isSp),
                    'close': (!descContent.prize && isSp),
                }"
                class="h3-button prize"
            ><span>景品の受け取り方法</span></button>

            <div
                :class="{
                    'open': (descContent.prize && isSp),
                    'close': (!descContent.prize && isSp),
                }"
                class="stamp-toggle-content"
            >
                <div class="heading">1.景品はマイジムでのみ受け取ることができます。</div>
                <p class="u-mt-30">マイジム登録をしていない場合、景品を受け取ることはできません。マイジム登録は、1年以内に参加したポケモンカードジムのみ、最大3店舗まで登録可能です。</p>
                <p><router-link :to="{name: 'MyGym'}">マイジム登録はこちらから</router-link></p>

                <div class="heading u-mt-30">2.マイジムにて、「ジムスタンプ景品受け取り専用二次元コード」を読み込んでください。</div>
                <p class="u-mt-20">マイジムにて、お店の方に「ポケモンカードのジムスタンプ景品を受け取りたい」とお尋ねください。</p>
                <p class="u-mt-20">お店の方が提示する「ジムスタンプ景品受け取り専用二次元コード」を読み込むことで、「ジムスタンプ獲得による景品受け取りの確認ページ（画像１）」にアクセスができ、その時点でスタンプが集まっていれば、景品の受け取り（画像2）が可能です。</p>

                <div class="stamp-detail-container">
                    <div class="content">
                        <div class="title">
                            （画像1）ジムスタンプ獲得による景品受け取りの確認ページ
                        </div>
                        <div class="image">
                            <img class="ss01" src="@/assets/img/prize/ss01.png">
                        </div>
                    </div>
                    <div class="content">
                        <div class="title">
                            （画像2）ジムスタンプの獲得状況による受け取りボタン
                        </div>
                        <div class="image">
                            <img class="ss02" src="@/assets/img/prize/ss02.png">
                            <p>ジムスタンプが集まっていて、景品の受け取りが可能なボタン</p>
                            <img class="ss03" src="@/assets/img/prize/ss03.png">
                            <p>ジムスタンプが不足していて、景品の受け取りが不可能なボタン</p>
                        </div>
                    </div>
                </div>

                <ul class="u-mt-20">
                    <li class="u-text-kome">受け取る前に、該当する景品の在庫をお店の方に必ずご確認ください。交換可能な場合は、お店の方に画面を見せながら、「担当者とともに、上記景品が受け取り可能であることを確認しました」欄にチェックを入れたうえ、「景品を受け取る」ボタンを押してください。お店の方が確認していない状態で、誤って「景品を受け取る」を押してしまった場合、景品は受け取れません。</li>
                    <li class="u-text-kome u-mt-20">お店によっては、ポケモンカードゲームの担当者が不在の場合には受け取れないケースもございますので、ご注意ください。</li>
                    <li class="u-text-kome u-mt-20">対象ジムにて景品が欠品している場合、受け取りができません。景品受け取りが目的の場合は、事前に対象ジムに景品の有無をご確認ください。</li>
                </ul>
            </div>

            <h3 class="u-mt-48">あつめたジムスタンプ</h3>
            <div
                v-if="hasThisSeason"
                class="p-gymStampContainer"
            >
                <div class="p-gymStampContainer_title">
                    開催中のキャンペーンの<br class="u-display-sp">ジムスタンプ
                </div>
                <div class="p-gymStampContainer_header">
                    <div class="detail">
                        <div class="title">{{ stampPrize.thisSeason.season_name }}</div>
                        <div class="period">
                            開催期間：{{ dayjsFormat(stampPrize.thisSeason.season_start_datetime.date, 'YYYY/MM/DD') }}~{{ dayjsFormat(stampPrize.thisSeason.season_end_datetime.date, 'YYYY/MM/DD') }}
                        </div>
                    </div>
                    <div
                        :class="{
                            first: stampPrize.thisSeason.stamp_color == 1,
                            second: stampPrize.thisSeason.stamp_color == 2
                        }"
                        class="stamp-container"
                    >
                        <div class="title">あつめたジムスタンプ</div>
                        <ul
                            v-if="hasThisSeason"
                            class="stamp"
                        >
                            <template v-if="exchangePrize.thisSeason.participation > 0">
                                <li
                                    v-for="i in exchangePrize.thisSeason.participation"
                                    :key="`si${i}`"
                                    class="get"
                                ><span class="stamp-icon"></span></li>
                            </template>
                            <template v-if="exchangePrize.thisSeason.participation < maxStampCount">
                                <li
                                    v-for="i in (maxStampCount - exchangePrize.thisSeason.participation)"
                                    :key="`si${i}`"
                                ><span class="stamp-icon"></span></li>
                            </template>
                        </ul>
                    </div>
                </div>
                <div
                    :class="{
                        'open': (stampContent.first && isSp),
                        'close': (!stampContent.first && isSp)
                    }"
                    class="p-gymStampContainer_main"
                >
                    <div
                        v-for="prize in stampPrize.thisSeason.prize"
                        :key="`prize-${prize.id}`"
                        :class="{
                            'exchanged': prize.exchanged_flg
                        }"
                        class="content"
                    >
                        <div class="explanation">
                            <span v-if="!prize.exchanged_flg && prize.possible_exchange_flg" class="replaceable">交換可能</span>
                            <span class="text">
                                ジムスタンプ{{ prize.cond_of_ex }}個達成で<br>
                                もらえる景品
                            </span>
                        </div>
                        <div class="image">
                            <img v-if="prize.image_url" :src="prize.image_url" class="prize-image">
                            <img v-else src="@/assets/img/prize/no_image.png" class="prize-image">
                        </div>
                        <div class="title">
                            {{ prize.name }}
                        </div>
                        <div class="desc">
                            {{ prize.detail }}
                        </div>
                        <div class="period">
                            景品受け取り期限：{{ dayjsFormat(stampPrize.thisSeason.exchange_limit_datetime.date, 'YYYY/MM/DD') }}まで
                        </div>
                    </div>
                </div>
                <div
                    v-if="isSp"
                    class="p-gymStampContainer_button"
                >
                    <button
                        @click="stampContent.first = !stampContent.first"
                        :class="{
                            'open': (stampContent.first && isSp),
                            'close': (!stampContent.first && isSp),
                        }"
                        class="c-btn"
                    >{{ stampContent.first ? '閉じる' : '景品を確認する' }}</button>
                </div>
            </div>

            <div
                v-if="hasPreviousSeason"
                id="previousSeason"
                class="p-gymStampContainer"
            >
                <div class="p-gymStampContainer_title">
                    過去に開催されたキャンペーンの<br class="u-display-sp">ジムスタンプ
                    <div class="u-text-kome">
                        景品受け取り期限を超えたものは表示されません。
                    </div>
                </div>
                <div class="p-gymStampContainer_header">
                    <div class="detail">
                        <div class="title">{{ stampPrize.previousSeason.season_name }}</div>
                        <div class="period">
                            開催期間：{{ dayjsFormat(stampPrize.previousSeason.season_start_datetime.date, 'YYYY/MM/DD') }}~{{ dayjsFormat(stampPrize.previousSeason.season_end_datetime.date, 'YYYY/MM/DD') }}
                        </div>
                    </div>
                    <div
                        :class="{
                                first: stampPrize.previousSeason.stamp_color == 1,
                                second: stampPrize.previousSeason.stamp_color == 2
                        }"
                        class="stamp-container"
                    >
                        <div class="title">あつめたジムスタンプ</div>
                        <ul
                            v-if="hasPreviousSeason"
                            class="stamp"
                        >
                            <template v-if="exchangePrize.previousSeason.participation > 0">
                                <li
                                    v-for="i in exchangePrize.previousSeason.participation"
                                    :key="`si${i}`"
                                    class="get"
                                ><span class="stamp-icon"></span></li>
                            </template>
                            <template v-if="exchangePrize.previousSeason.participation < maxStampCount">
                                <li
                                    v-for="i in (maxStampCount - exchangePrize.previousSeason.participation)"
                                    :key="`si${i}`"
                                ><span class="stamp-icon"></span></li>
                            </template>
                        </ul>
                    </div>
                </div>
                <div
                    :class="{
                        'open': (stampContent.second && isSp),
                        'close': (!stampContent.second && isSp),
                    }"
                    class="p-gymStampContainer_main"
                >
                    <div
                        v-for="prize in stampPrize.previousSeason.prize"
                        :key="`prize-${prize.id}`"
                        :class="{
                            'exchanged': prize.exchanged_flg
                        }"
                        class="content"
                    >
                        <div class="explanation">
                            <span v-if="!prize.exchanged_flg && prize.possible_exchange_flg" class="replaceable">交換可能</span>
                            <span class="text">
                                ジムスタンプ{{ prize.cond_of_ex }}個達成で<br>
                                もらえる景品
                            </span>
                        </div>
                        <div class="image">
                            <img v-if="prize.image_url" :src="prize.image_url" class="prize-image">
                            <img v-else src="@/assets/img/prize/no_image.png" class="prize-image">
                        </div>
                        <div class="title">
                            {{ prize.name }}
                        </div>
                        <div class="desc">
                            {{ prize.detail }}
                        </div>
                        <div class="period">
                            景品受け取り期限：{{ dayjsFormat(stampPrize.previousSeason.exchange_limit_datetime.date, 'YYYY/MM/DD') }}まで
                        </div>
                    </div>
                </div>
                <div
                    v-if="isSp"
                    class="p-gymStampContainer_button"
                >
                    <button
                        @click="stampContent.second = !stampContent.second"
                        :class="{
                            'open': (stampContent.second && isSp),
                            'close': (!stampContent.second && isSp),
                        }"
                        class="c-btn"
                    >{{ stampContent.second ? '閉じる' : '景品を確認する' }}</button>
                </div>
            </div>

            <div class="u-text-center u-mt-exlg">
                <router-link
                    :to="{name: 'Mypage'}"
                    class="c-btn c-btn-primary-outline"
                >
                    マイページへ
                </router-link>
            </div>
        </div>
    </system-page>
</template>

<script>
import dayjs from 'dayjs'
import ja from 'dayjs/locale/ja'
import SystemPage from '@/component/SystemPage.vue'

const MAX_STAMP_COUNT = 4

export default {
    name: 'MypageStamp',
    components: {
        SystemPage,
    },
    data() {
        return {
            page: {
                title: 'ジムスタンプ獲得でもらえる景品・受け取り方法・期限について',
                breakedTitle: 'ジムスタンプ獲得でもらえる景品・受け取り<br class="u-display-pc">方法・期限について',
                desc: 'ポケモンカードゲーム プレイヤーズクラブ ジムスタンプ獲得でもらえる景品・受け取り方法・期限 についてのページ。',
                breadcrumb: [
                    { name: 'マイページ', path: '/mypage' },
                ],
            },
            stampContent: {
                first: false,
                second: false,
            },
            descContent: {
                stamp: false,
                prize: false,
            },
            exchangePrize: {
                thisSeason: [],
                previousSeason: []
            },
            stampPrize: {
                thisSeason: [],
                previousSeason: []
            },
            maxStampCount: MAX_STAMP_COUNT
        }
    },
    watch: {
        hasPreviousSeason: function(previousSeason) {
            const hashId = 'previousSeason'
            if (previousSeason && (this.$route.hash == `#${hashId}`)) {
                this.$nextTick(function() {
                    const element = document.getElementById(hashId)
                    if (element) {
                        element.scrollIntoView({block: 'start'})
                    }
                })
            }
        }
    },
    computed: {
        isSp() {
            return this.$store.state.isSp;
        },
        // シーズン情報がないときは空のArray、ある時はObjectになる
        hasThisSeason() {
            return !Array.isArray(this.exchangePrize.thisSeason) && !Array.isArray(this.stampPrize.thisSeason)
        },
        hasPreviousSeason() {
            return !Array.isArray(this.exchangePrize.previousSeason) && !Array.isArray(this.stampPrize.previousSeason)
        },
    },
    created: function() {
        this.init()
    },
    methods: {
        init() {
            const getExchangedPrizeApi = '/get_exchanged_prize'
            const getStampPrizeApi = '/get_stamp_prize'

            this.axios.get(getExchangedPrizeApi)
            .then(response => {
                this.exchangePrize.thisSeason = response.data.this_season
                this.exchangePrize.previousSeason = response.data.previous_season
            })
            .catch(err => {
                this.error = err.response.data.message
            })

            this.axios.get(getStampPrizeApi)
            .then(response => {
                this.stampPrize.thisSeason = response.data.this_season
                this.stampPrize.previousSeason = response.data.previous_season
            })
            .catch(err => {
                this.error = err.response.data.message
            })
        },
        dayjsFormat(date, format) {
            dayjs.locale(ja)

            return dayjs(date).format(format)
        },
    },
}
</script>

<style lang="scss" scoped>
@use '~@/assets/style/global' as g;

h3 {
    & {
        font-size: 18px;
        line-height: 27px;
        font-weight: bolder;
    }
    &::before {
        content: '●';
        margin-right: .5em;
    }
}

.h3-button {
    & {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        height: 60px;
        padding: 16px 0;
        font-size: 18px;
        line-height: 27px;
        font-weight: bolder;
        border-top: 1px solid g.$color-border-gray;

        @include g.pc {
            display: none;
        }

        &.stamp {
            border-bottom: 1px solid transparent;
        }
        &.prize {
            &.open {
                border-bottom: 1px solid transparent;
            }
            &.close {
                margin-bottom: 25px;
                border-bottom: 1px solid g.$color-border-gray;
            }
        }

        &::before {
            content: '●';
            margin-right: .5em;
        }

        &::after {
            @include g.setIconFont('\e900');
            font-size: 19px;
            margin-right: 8px;
        }
        &.open {
            &::after {
                transform: rotate(-180deg);
            }
        }

        & > span {
            flex: 1;
        }
    }
}

.u-text-kome {
    font-size: 14px;
    line-height: 21px;
    color: #3C3C3C;
}

.u-mt-48 {
    @include g.pc {
        margin-top: 48px
    }
}

.stamp-banner {
    display: block;
    margin-left: auto;
    margin-right: auto;
    @include g.pc {
        max-width: 476px;
        max-height: 148px;
    }
}

.stamp-toggle-content {
    & {
        @include g.sp {
            & {
                margin-bottom: 32px;
            }
            &.open {
                display: block;
            }
            &.close {
                display: none;
            }
        }
    }
    & > .heading {
        margin-top: 30px;
        font-size: 20px;
        line-height: 28px;
        font-weight: bolder;
        padding-bottom: 10px;
        border-bottom: 1px solid g.$color-black;
    }
}

.stamp-detail-container {
    & {
        display: flex;
        justify-content: center;
        gap: 20px;
        margin-top: 20px;

        @include g.sp {
            flex-direction: column;
        }
    }
    & > .content {
        & {
            width: 335px;
            padding: 16px;
            border: 1px solid g.$color-black-a025;
            border-radius: 12px;

            @include g.sp {
                width: 100%;
            }
        }
        & > .title {
            font-size: 16px;
            line-height: 28px;
            font-weight: bolder;

            @include g.pc {
                text-align: center;
            }
        }
        & > .image {
            & {
                text-align: center;
            }
            & > img {
                margin-top: 16px;

                &.ss01 {
                    max-width: 131px;
                    @include g.sp {
                        max-width: 183px;
                    }
                }
                &.ss02 {
                    max-width: 157px;
                    @include g.sp {
                        max-width: 210px;
                    }
                }
                &.ss03 {
                    max-width: 157px;
                    @include g.sp {
                        max-width: 210px;
                    }
                }
            }
            & > p {
                margin-top: 8px;
                font-size: 16px;
                line-height: 28px;

                @include g.sp {
                    text-align: left;
                }
            }
        }
    }
}

.p-gymStampContainer {
    margin-top: 48px;
}
</style>
