<template>
    <header class="header">
        <div class="header-container">
            <div class="header-nav-logo">
                <a :href="topUrl">
                    <h1>
                        <img src="@/assets/img/header/logo.svg" alt="site logo" />
                    </h1>
                </a>
            </div>
            <nav class="header-nav-pc u-display-pc">
                <ul>
                    <li><a href="https://www.pokemon-card.com/about/">はじめての方へ</a></li>
                    <li><a href="https://www.pokemon-card.com/rules/">遊びかたと<br>大会ルール</a></li>
                    <li><a href="https://www.pokemon-card.com/products/">商品情報</a></li>
                    <li><a href="https://map.pokemon-card.com/" target="_blank">お店検索</a></li>
                    <li><a href="https://www.pokemon-card.com/card-search">カード検索</a></li>
                    <li><a href="https://www.pokemon-card.com/deck/">デッキ構築</a></li>
                    <li class="header-nav-pc-sub">
                        <button @click="linkEventPage(null)">イベント</button>
                        <header-sub-menu-for-event class="submenu event">
                            <div class="event-left">
                                <div class="event-enjoy">
                                    <button
                                        @click="linkEventPage('#enjoy')"
                                        :disabled="isEventPageDisabled('#enjoy')"
                                    >
                                        <img src="@/assets/img/header/enjoy.png">
                                    </button>
                                </div>
                                <div class="event-challenge">
                                    <button
                                        @click="linkEventPage('#challenge')"
                                        :disabled="isEventPageDisabled('#challenge')"
                                    >
                                        <img src="@/assets/img/header/challenge.png">
                                    </button>
                                </div>
                            </div>
                            <div class="event-right">
                                <ul class="link">
                                    <li>
                                        <button
                                            class="c-btn c-btn-primary-outline top"
                                            @click="linkEventPage(null)"
                                        >イベントトップ</button>
                                    </li>
                                    <li>
                                        <router-link
                                            :to="{name: 'EventSearch'}"
                                            class="c-btn c-btn-primary search"
                                        >イベント検索</router-link>
                                    </li>
                                    <li>
                                        <router-link
                                            :to="{name: 'Mypage'}"
                                            class="c-btn c-btn-primary players"
                                        >プレイヤーズクラブ</router-link>
                                    </li>
                                </ul>
                            </div>
                        </header-sub-menu-for-event>
                    </li>
                    <li class="header-nav-pc-sub link-mypage">
                        <router-link to="/mypage"><i class="icon-my_page"></i></router-link>
                        <header-sub-menu class="submenu mypage"
                        >
                            <header-player-content :user="user" />
                        </header-sub-menu>
                    </li>
                </ul>
            </nav>
            <div class="header-sub u-display-pc">
                <ul>
                    <li>
                        <button
                            @click="toggleSearchMenu"
                            class="header-sub-search"
                        >
                            <i class="icon-search"></i>
                        </button>
                    </li>
                    <li>
                        <a href="https://www.youtube.com/channel/UCysOBNRW0rEmLtFptj1TN7A" target="_blank" class="header-sub-youtube">
                            <i class="icon-youtube"></i>
                        </a>
                    </li>
                    <li>
                        <a href="https://twitter.com/pokemon_cojp" target="_blank" class="header-sub-twitter">
                            <i class="icon-x"></i>
                        </a>
                    </li>
                </ul>
            </div>
            <div class="header-buttons u-display-sp">
                    <button
                        @click="toggleSpMenu()"
                        :class="{ open: openSpMenu }"
                        class="header-menu-btn"
                    >
                        {{ openSpMenu ? "開く" : "閉じる" }}
                    </button>
            </div>
        </div>
        <header-search v-model:isOpenForm="isOpenForm" />
        <div class="header-nav-sp" :class="{ open: openSpMenu }">
            <header-player-content :user="user" />
            <nav>
                <ul class="header-nav-sp-list">
                    <li><a href="https://www.pokemon-card.com/about/" class="beginner">はじめての方へ</a></li>
                    <li><a href="https://www.pokemon-card.com/rules/">遊びかたと大会ルール</a></li>
                    <li><a href="https://www.pokemon-card.com/products/">商品情報</a></li>
                    <li><a href="https://map.pokemon-card.com/" target="_blank">お店検索</a></li>
                    <li><a href="https://www.pokemon-card.com/card-search">カード検索</a></li>
                    <li><a href="https://www.pokemon-card.com/deck/">デッキ構築</a></li>
                    <li id="sp-event-top" class="close">
                        <button
                            @click="toggleSpSubMenu('sp-event')"
                            type="button"
                            class="event-open"
                        >イベント</button>
                        <header-sub-menu id="sp-event" class="submenu event">
                            <ul class="link">
                                <li>
                                    <button
                                        @click="linkEventPage(null)"
                                        class="top"
                                    >
                                        イベントトップ
                                    </button>
                                </li>
                                <li>
                                    <button
                                        @click="linkEventPage('#enjoy')"
                                        class="enjoy"
                                    >
                                        <div class="flex">
                                            <span>イベント初心者の方はこちら</span>
                                            エンジョイ！
                                        </div>
                                    </button>
                                </li>
                                <li>
                                    <button
                                        @click="linkEventPage('#challenge')"
                                        class="challenge"
                                    >
                                        <div class="flex">
                                            <span>真剣勝負を楽しみたい方はこちら</span>
                                            チャレンジ！
                                        </div>
                                    </button>
                                </li>
                                <li>
                                    <router-link
                                        :to="{name: 'EventSearch'}"
                                        class="search"
                                    >イベント検索</router-link>
                                </li>
                            </ul>
                        </header-sub-menu>
                    </li>
                </ul>
            </nav>
            <div>
                <div class="players-logo">
                    <img src="@/assets/img/header/sp/logo.png">
                </div>
                <div v-if="!isLogin" class="sp-mypage">
                    <div class="sp-mypage-text">
                        <i class="icon-my_page"></i>
                        <p>マイページでは、イベントのエントリー確認やマイデッキの登録ができるよ</p>
                    </div>
                    <ul class="sp-mypage-link">
                        <li>
                            <router-link :to="{name: 'Mypage'}">マイページ</router-link>
                        </li>
                        <li><router-link :to="{name: 'MailRegister'}">新規会員登録</router-link></li>
                    </ul>
                </div>
                <div class="sp-footer">
                    <ul class="sns">
                        <li>
                            <a href="https://www.youtube.com/channel/UCysOBNRW0rEmLtFptj1TN7A" target="_blank" class="sns-youtube">
                                <i class="icon-youtube"></i>
                            </a>
                        </li>
                        <li>
                            <a href="https://twitter.com/pokemon_cojp" target="_blank" class="sns-twitter">
                                <i class="icon-x"></i>
                            </a>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </header>
</template>

<script>
import HeaderSubMenu from "./subMenu.vue";
import HeaderSubMenuForEvent from "./subMenuForEvent.vue";
import HeaderPlayerContent from "./player.vue";
import HeaderSearch from "./search.vue";

const EVENT_PAGE_NAME = 'EventTop'

export default {
    name: "pcgHeader",
    components: {
        HeaderSubMenu,
        HeaderSubMenuForEvent,
        HeaderPlayerContent,
        HeaderSearch,
    },
    data: function () {
        return {
            openSpMenu: false,
            isOpenForm: false,
        };
    },
    computed: {
        isLogin() {
            return this.$store.state.loggedIn
        },
        user() {
            return this.$store.state.user
        },
        topUrl() {
            return process.env.VUE_APP_TRAINERS_WEB_URL
        },
        selectedEventPage() {
            return this.$store.state.selectedEventPage;
        },
    },
    watch: {
        '$route': function() {
            this.openSpMenu = false
            this.user = this.$store.getters.getUser()
        },
    },
    methods: {
        toggleSpMenu() {
            this.openSpMenu = !this.openSpMenu;
        },
        toggleSearchMenu() {
            this.isOpenForm = !this.isOpenForm;
        },
        toggleSpSubMenu(id) {
            const content = document.querySelector(`#${id}`)
            if (content.style.display == 'none' || content.style.display == '') {
                content.style.display = 'block'
            } else {
                content.style.display = 'none'
            }

            const contentTop = document.querySelector(`#${id}-top`)
            if (contentTop.classList.contains('close')) {
                contentTop.classList.add('open')
                contentTop.classList.remove('close')
            } else {
                contentTop.classList.add('close')
                contentTop.classList.remove('open')
            }
        },
        linkEventPage(hash) {
            if (EVENT_PAGE_NAME == this.$route.name) {
                // イベントトップからイベントトップを選択
                if (this.$route.hash == "" && hash == null) {
                    // 初期表示から初期表示（何もしない）

                    return
                } else if (this.$route.hash != "" && hash == null) {
                    // 選択済から初期表示
                    this.$router.replace({name: EVENT_PAGE_NAME})
                    location.reload()

                    return
                } else if (this.$route.hash == "" && hash != null) {
                    // 初期表示から選択済
                    this.$store.commit('setSelectedEventPage', hash)
                    window.location.hash = hash
                    location.reload()

                    return
                } else if (this.$route.hash != "" && hash != null) {
                    // 選択済から選択済
                    if (
                        (this.$route.hash == "#enjoy" && hash != "#enjoy") ||
                        (this.$route.hash == "#challenge" && hash != "#challenge")
                    ) {
                        // 別の選択済ページ
                        this.$store.commit('setSelectedEventPage', hash)
                        window.location.hash = hash
                        location.reload()

                        return
                    } else {
                        // 同じ選択済ページ（何もしない）
                        return
                    }
                }
            } else {
                // イベントトップ以外のページからイベントトップ
                if (hash == null) {
                    this.$router.push({name: EVENT_PAGE_NAME})

                    return
                }

                if (EVENT_PAGE_NAME != this.$route.name) {
                    this.$store.commit('setSelectedEventPage', hash)
                    this.$router.push({name: EVENT_PAGE_NAME, hash: hash})

                    return
                }
            }
        },
        isEventPageDisabled(hash) {
            if (EVENT_PAGE_NAME == this.$route.name) {
                return this.$route.hash == hash
            } else {
                return false
            }
        }
    },
};
</script>

<style lang="scss" scoped>
@use "~@/assets/style/global" as g;
@use "./style/mypage";
@use "./style/event";
@use "./style/sp-footer";
@use "./style/sp";

.header {
    &-container {
        position: relative;
        display: flex;
        z-index: g.$z-index-header;
        background-color: g.$color-black;
        background-image: url("~@/assets/img/header/logo-header.png");
        background-position: left top;
        background-repeat: no-repeat;

        a {
            color: g.$color-font-white;
            &:hover {
                text-decoration: none;
            }
        }
    }

    @include g.pc {
        &-container {
            height: g.$header-height-pc;
            align-items: center;
            justify-content: space-between;
            padding-right: g.$margin-large;
        }

        &-nav-logo {
            position: relative;
            display: flex;
            align-self: flex-start;
            flex-shrink: 0;
            width: 270px;
            height: 89px;

            & > a {
                position: relative;
                display: inline-block;
                width: 100%;
                height: 100%;
            }

            img {
                position: absolute;
                left: 15px;
                top: 47%;
                width: 216px;
                transform: translate(0,-50%);
            }
        }

        &-nav-sp {
            display: none;
        }
        &-nav-pc {
            @include g.font-sm;
            height: 100%;
            display: flex;
            align-items: flex-end;

            & > ul {
                display: flex;
                justify-content: space-between;
                align-items: flex-start;
                width: 643px;
                height: 100%;

                & > li {
                    height: 100%;
                    &:not(:last-child) {
                        padding-right: 6px;
                    }
                    &:not(:first-child) {
                        padding-left: 6px;
                    }
                    &:hover {
                        a,
                        button {
                            color: g.$color-green;
                        }
                        .header-nav-sub {
                            display: flex;
                        }
                    }
                    & > a,
                    & > button {
                        display: flex;
                        line-height: g.$line-height;
                        align-items: center;
                        justify-content: center;
                        height: 100%;
                        color: g.$color-white;
                        cursor: pointer;
                    }
                    &.link-mypage {
                        padding: 0 g.$margin;
                        font-size: 24px;
                    }
                    &.header-nav-pc-sub {
                        &:hover {
                            & > a,
                            & > button {
                                position: relative;
                                &::after {
                                    content: "";
                                    position: absolute;
                                    bottom: 0;
                                    left: 0;
                                    right: 0;
                                    margin: auto;
                                    width: min-content;
                                    border-bottom: 10px solid g.$color-white;
                                    border-right: 10px solid transparent;
                                    border-left: 10px solid transparent;
                                }
                            }
                        }
                    }
                }
            }
        }
        &-sub {
            width: 103px;
            padding-left: g.$margin;
            border-left: 1px solid rgba(g.$color-white, 0.5);
            &-search,
            &-youtube,
            &-twitter {
                display: inline-flex;
                align-items: center;
                justify-content: center;
                width: 24px;
                height: 24px;
                overflow: hidden;
                & > i {
                    &::before {
                        line-height: 24px;
                        vertical-align: middle;
                    }
                }
            }
            &-search {
                color: g.$color-white;
                cursor: pointer;
            }
            &-youtube {
                background-color: g.$color-youtube;
            }
            &-twitter {
                background: g.$color-white;
            }
            .icon-search {
                font-size: 20px;
            }
            .icon-youtube {
                font-size: 12px;
            }
            .icon-x {
                font-size: 16px;
                color: g.$color-black;
            }
            .icon-twitter {
                font-size: 17px;
            }
            ul {
                display: flex;
                justify-content: space-between;
                align-items: center;
                li {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                }
            }
        }
    }

    @include g.sp {
        position: fixed;
        width: 100vw;
        top: 0;
        left: 0;
        right: 0;
        z-index: g.$z-index-header;
        &-container {
            justify-content: space-between;
            height: g.$header-height-sp;
            background-size: auto 104%;
        }
        &-nav-logo {
            position: relative;
            max-width: 264px;
            width: 60%;

            & > a {
                display: inline-block;
            }

            a,
            h1 {
                height: 100%;
            }

            img {
                position: absolute;
                top: 45%;
                left: 9px;
                transform: translate(0,-50%);
                width: 51%;
                min-width: 134px;
            }
        }
        &-buttons {
            display: flex;
            align-items: center;
            padding-right: g.$margin;
        }
        &-menu-btn {
            font-size: 0;
            width: 48px;
            &::before {
                @include g.setIconFont("\e90c");
                display: block;
                width: fit-content;
                margin: auto;
                font-size: 23px;
                color: g.$color-white;
            }
            &.open::before {
                content: "\e901";
                font-size: 29px;
            }
        }
        &-nav-sp {
            position: fixed;
            top: g.$header-height-sp;
            bottom: 0;
            right: 100%;
            width: 100%;
            max-width: 100vw;
            height: calc(100vh - #{g.$header-height-sp});
            overflow-y: scroll;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            background-color: g.$color-white;

            &.open {
                right: 0;
                transition: right .3s;
            }
            &-list {
                margin-top: 8px;
                font-weight: bold;

                li {
                    margin: 0 16px;
                    border-bottom: 1px solid g.$color-border-gray;

                    &.close {
                        button {
                            &::after {
                                @include g.setIconFont("\e902");
                            }
                        }
                    }
                    &.open {
                        border-color: rgba(g.$color-white, 0);

                        button.event-open {
                            border-bottom: 1px dotted g.$color-border-gray;
                            &::after {
                                @include g.setIconFont("\e91a");
                            }
                        }
                    }

                    button {
                        &::after {
                            position: absolute;
                            top: 0;
                            right: g.$margin;
                            bottom: 0;
                            margin: auto;
                            height: fit-content;
                        }
                    }

                    a,
                    button {
                        position: relative;
                        display: inline-block;
                        width: 100%;
                        height: 60px;
                        padding: g.$margin 0 16px;
                        font-size: 20px;
                        line-height: 1.45;
                        color: g.$color-font;
                        &.beginner {
                            &::before {
                                @include g.setIconFont("\e915");
                                display: inline-block;
                                margin-right: 11px;
                                transform: translateY(1px);
                            }
                        }
                    }
                }
            }
        }
        &-nav-sub {
            display: none;
        }
    }
}
</style>
